import React, { useEffect, useRef, useState } from "react"
import { graphql, PageProps,} from "gatsby"
import Layout from "../components/layout"
import styled from 'styled-components';
import { motion, useAnimation } from "framer-motion"
import {Logo, Link, Square} from "../components/utils"

import { WallQuery } from "./__generated__/WallQuery"
import StackGrid from "react-stack-grid";

export default ({ data, location }: PageProps<WallQuery>) => {
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    useEffect(() => {
        const w = window
        const d = document
        const e = d.documentElement
        const g = d.getElementsByTagName("body")[0]

        setWindowWidth(w.innerWidth || e.clientWidth || g.clientWidth);
        setWindowHeight(w.innerHeight || e.clientHeight || g.clientHeight);
    });
    const aspect = (Math.max(windowHeight, windowWidth) - Math.min(windowHeight, windowWidth)) / 2;

    let i = 0;
    let list: JSX.Element[] = [];
    list.push(
        <div className="lg:hidden">
            <Fade>
                <div style={{
                    width: windowWidth > windowHeight ? '50vh' : '100%',
                    height: '50vh',
                    marginTop: aspect,
                }}
                className="mx-auto ">
                    <Square>
                        <Logo/>
                    </Square>
                </div>
            </Fade>
        </div>
    );
    list.push(...data.allMicrocmsWall.edges.map(
        (item) => {
            if(item.node.isDummy){return null;}
            if (item.node.news) {
                return banner(
                    'NEWS',
                    `【${item.node.news.type}】${item.node.news.title}`,
                    item.node.news.image ?
                        item.node.news.image.url :
                        null,
                    item.node.news.link,
                );
            } else if (item.node.discography) {
                return banner(
                    'DISCOGRAPHY',
                    item.node.discography.title,
                    item.node.discography.image ?
                        item.node.discography.image.url :
                        null,
                    `/discography/${item.node.discography.identity}`,
                );
            } else if (item.node.general) {
                return banner(
                    item.node.general.subtitle,
                    item.node.general.title,
                    item.node.general.image ? item.node.general.image.url : null,
                    item.node.general.link,
                );
            }
        }
    ));

    return (
        <Layout
            front={true}
            seo={{
                title: "Home",
            }}
            navPlaceholder={false}
            location={location}
        >
            <StackGrid
                columnWidth={'100%'}
                gutterWidth={0}
                gutterHeight={0}
            >
                {list}
            </StackGrid>
        </Layout>
    )
}

const Fade = ({ children }) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{
                opacity: 1,
            }}
        >
            {children}
        </motion.div>
    )
}

const banner = (category, title, image, link) => {
    const BG = styled.div`
        background-color: #000;
        opacity: 0.85;
        &:hover {
        opacity: 0.25;
        //background-image: linear-gradient(to right, #f55555, #6888df);
        mix-blend-mode: hard-light;
        }
    `;
    let img = image ? `url(${image})` : 'linear-gradient(to right, #f55555, #6888df)';

    return (
        <Link to={link}>
            <div className="banner h-40 flex"
                style={{
                    backgroundImage: img,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                }}>

                <BG className="w-full h-full absolute transition duration-75 pointer-events-none lg:pointer-events-auto" />
                <div className={`w-full h-full p-5 absolute pointer-events-none `} >
                    <h2 className="text-color-1 text-3xl lg:text-5xl my-auto " style={{
                        mixBlendMode: 'difference',
                    }}>
                        {category}
                    </h2>
                    <h3 className="text-xl">
                        {title}
                    </h3>

                </div>
            </div >
        </Link>
    )
}

export const query = graphql`
    query WallQuery {
        allMicrocmsWall {
            edges {
              node {
                id
                news {
                  image {
                    url
                  }
                  title
                  link
                  type
                }
                general {
                  title
                  subtitle
                  image {
                    url
                  }
                  link
                }
                discography {
                  title
                  image {
                    url
                  }
                  identity
                }
                isDummy
              }
            }
          }
        site {
            siteMetadata {
                logo
            }
        }
    }
`
